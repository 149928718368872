import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Document, PageTitle } from "@components"

const Privacy = () => (
  <Layout>
    <Seo title="Privacy policy" />
    <PageTitle title="Privacy policy" />
    <Document>
      <h4>Scope of this policy</h4>
      <p>The privacy policy sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed and used by our company as the data controller responsible for your personal data.</p>
      <h4>We will collect and process the following data about you:</h4>
      <ol>
        <li>Information you give us ("Submitted Information"): this is information you give us by filling in forms on our website, by corresponding with us (for example, by e-mail or via the chat functions on the website) or by actions you perform when using our website, such as expressing your interest for a project. If you contact us, we will keep a record of that correspondence.</li>
        <li>Information we collect about you and your device, each time you visit our website, such as technical information, including the internet protocol (IP) address used to connect your computer to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform; information about your visit, including the full uniform resource locators (URL), navigation path and services you viewed or searched for; location Information, by using your IP address to determine your location.</li>
      </ol>
      <p>We will only collect information that is necessary for us to provide you with the product or service that you have requested. The type of information that we may collect will depend upon the nature of that service or product.</p>
      <h4>We will use your personal data for the following purposes:</h4>
      <ol>
        <li>provide you with specific services in accordance with a contract you are entering, or have entered into with us;</li>
        <li>carry out regulatory reporting when required by the type of service provided by us;</li>
        <li>provide you with newsletters or alerts where you have signed-up for these on the website;</li>
        <li>conduct market research surveys, where you choose to participate in these;</li>
        <li>analyse usage of the website in compliance with the terms of our cookies policy.</li>
      </ol>
      <h4>We will always use your data according to the principles of:</h4>
      <ol>
        <li>Legitimate Interest: the interest of ours as a business to provide to you our services and offer the most secure experience. We do not use your personal data for activities where our interests are overridden by the impact on you, unless we have your consent or are otherwise required or permitted to by law.</li>
        <li>Fulfilling a Contract: where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.</li>
        <li>Our Legal Obligation: where it is necessary for compliance with a legal or regulatory obligation that we are subject to.</li>
      </ol>
      <h4>Marketing</h4>
      <p>We may use your personal data to form a view on what we think you may want or need, or what may be of interest to you. You will receive marketing communications from us if you have signed up to and/or utilise our services and, in each case, you have not opted out of receiving marketing notifications. We will obtain your express opt-in consent before we share your personal data with any third party for marketing or promotional purposes.</p>
      <h4>Disclosure of your data</h4>
      <ol>
        <li>Data processing parties: we will disclose your data to certain third parties who use personal data in delivering their services to us. They use data securely and confidentially and under strict contractual controls in accordance with data protection laws. Such parties include cloud storage providers, banking and financial services partners, marketing services and analytics providers.</li>
        <li>Third party services partners: we may share your data in order to provide certain services to you upon your request. From time-to-time, we may partner with certain third parties in order to offer you co-branded services or promotional offers. We will obtain your express opt-in consent before we share your personal data with any third party and you can withdraw your consent at any time.</li>
      </ol>
      <h4>International transfer of data</h4>
      <p>We are an international organisation, with businesses both inside and outside of the European Economic Area ("EEA"). Third party service providers who handle data on our behalf may be based in locations around the world, and we may also be subject to scrutiny from courts or regulators in a number of different jurisdictions. For these reasons, your Personal Information may be transferred to other countries both inside and outside of the EU and the EEA. As privacy laws in other countries may not be equivalent to those in your home country, we only make arrangements to transfer data overseas where we are satisfied that adequate levels of protection are in place to protect any information held in that country or that the service provider acts at all times in compliance with applicable privacy laws. Where required under applicable laws we will take measures to ensure that Personal Information handled in other countries will receive at least the same level of protection as it is given in your home country, for instance by entering into contracts incorporating the European Commission approved model contract clauses.</p>
      <h4>Retention of your personal information</h4>
      <p>We will retain your personal information for as long as is reasonably necessary for the purposes listed above. However, in some circumstances we may retain your personal information for longer periods of time, for instance where we are required to do so in accordance with legal, tax or accounting obligations or to have an accurate record of your dealings with us in the event of any complaints or challenges.</p>
      <h4>Protection of your personal information</h4>
      <p>We will hold your personal information securely whilst it is under our control, including where it is processed by third party service providers on our behalf. We train our employees in respect of their obligations under data protection laws, and we ensure that only relevant employees, contractors and agents have access to your personal information. We take the security of our physical premises, our servers and the website seriously and we will take all appropriate technical measures using recognized security procedures and tools in accordance with good industry practice to protect your personal information across all of these platforms. Whilst we use all reasonable endeavours to protect your security in the manner described above, we consider that it is appropriate to advise you that data transmission over the Internet and the World Wide Web cannot always be guaranteed as 100% secure, and therefore that you use the website at your own risk.</p>
      <h4>Cookies</h4>
      <p>We use cookies and other tracking technologies to improve and tailor your browsing experience.</p>
      <h4>Your rights</h4>
      <p>Subject to applicable law, you may have some or all of the following rights in respect of your Personal Information:</p>
      <ol>
        <li>to obtain a copy of your personal information together with information about how and on what basis that information is processed;</li>
        <li>to rectify inaccurate data or have incomplete information completed;</li>
        <li>to erase your data where it is no longer necessary in relation to the purposes for which it was collected or processed;</li>
        <li>to restrict processing of your data where: the accuracy of the personal information is contested; the processing is unlawful but you object to the erasure of the personal information; we no longer require the personal information but it is still required for the establishment, exercise or defense of a legal claim; to challenge processing which we have justified on the basis of a legitimate interest (as opposed to your consent, or to perform a contract with you); to prevent us from sending you direct marketing; to withdraw your consent to our processing of your personal information (where that processing is based on your consent); to object to decisions which are based solely on automated processing or profiling.</li>
      </ol>
      <h4>Changes to this privacy policy</h4>
      <p>Any changes we may make to our privacy policy in the future will be posted on this page and, where appropriate, notified to you by e-mail. The new terms may be displayed on-screen and you may be required to read and accept them to continue your use of our services.</p>
      <h4>Contacts</h4>
      <p>All questions relating to data and your privacy are welcomed and should be addressed to our support team. If you have any questions, contact our support using the appropriate function on your dashboard.</p>
      <p>You can also find some helpful guidance on ICO website: <a href=" https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/"> https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/.</a></p>
      <p>In relation to the website, you can update, correct or delete your personal information by referring to the "Profile" section of your dashboard. You can also use the "Update settings" and "Unsubscribe" links in our emails to prevent us from sending you direct marketing communications.</p>
    </Document>
  </Layout>
)

export default Privacy
